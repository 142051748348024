<template>
  <FkStrapiSingle :set-seo-meta="slug !== ''">
    <template #append>
      <RegionCanonical />
      <RegionRobots />
    </template>
  </FkStrapiSingle>
</template>

<script setup lang="ts">
const { region } = useStaticData();
const route = useRoute();
const config = useRuntimeConfig();

config.public.flyingkiwi.siteName = region.value?.attributes?.title ?? "";

let { slug } = route.params;
if (Array.isArray(slug) && slug.length > 0) {
  slug = slug[0];
}

if (slug === "") {
  const title = `${region.value?.attributes?.seoTitle} - ${config.public.flyingkiwi.siteName}`;
  useSeoMeta({
    title: title,
    description: region.value?.attributes?.seoDescription ?? "",
  });
  useHead(() => {
    return {
      meta: [
        {
          name: "title",
          content: title,
        },
      ],
    };
  });
}
</script>
